.text-gradient-gold {
    background: #FCEABB;
    background: linear-gradient(to right, #FCEABB 0%, #FCCD4D 50%, #F8B500 51%, #FBDF93 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.buttonTicket {
    border-radius: 50px;
    text-align: center;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    margin: 3px;
    height: 35px;
    width: 35px;
    padding-top: 6px;
    font-size: 12px;
}

.buttonTicketSelected {
    background: rgb(255,156,0);
background: linear-gradient(90deg, rgba(255,156,0,1) 0%, rgba(255,224,0,1) 100%);
    border: none;
    color: black;
}

.buttonBuy{
    background-color: #01b574;
    border-radius: 10px;
    padding: 20px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
}

.dimissButtonStack {
    background-color: transparent;
    border: none;
    color: #fff;
}

.searchInput {
    background-color: #0f1535;
    border: 1px solid white;
    border-radius: 20px;
    padding: 10px;
    color: #ffffff;
}